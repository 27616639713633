import Layout from '@core/Layout'
import jsZip from 'jszip'
import Pristine from 'pristinejs'

import AddTime from '@components/AddTime'
import Comments from '@components/Comments'
import Labels from '@components/Labels'
import Schedule from '@components/Schedule'
// import { AddTimeLogDialog } from '@components/TimeLogDialog'
import { localeDatepicker } from '@utils/localeDatepicker'
// import { mountRegularReact } from '@utils/mountReact'
import { useHttp } from '@utils/useHttp'
import { useNotifications } from '@utils/useNotifications'

import Tip from '../../../@components/Tip'

export default class Task extends Layout {
  constructor(options) {
    super({
      pageType: 'task',
      selector: '.task-details-wrapper',
    })

    this.pristine = null

    this.$form = this.$document.find('form[name=task]')

    this.$downloadAllAttachmentsBtn = this.$target.find(
      '.download-all-attachments-btn',
    )

    this.AddTime = null
    this.Comments = null
    this.init()
  }

  init() {
    const { request } = useHttp()

    this.tip = new Tip({
      text: '',
      page: 'task',
    })

    if (this.$form.length > 0) {
      this.pristine = new Pristine(this.$form.get(0))

      const taskProjectSelect = this.$document.find('#task_project').select2({
        dropdownCssClass: 'project-select-drop',
        dropdownAdapter: $.fn.select2.amd.require(
          'CustomDropdownSearchAdapter',
        ),
        placeholderForSearch: Translator.trans('search.or_filter_projects'),
        width: '100%',
      })

      taskProjectSelect.on('select2:select', (e) => {
        this.pristine && this.pristine.validate()
      })

      taskProjectSelect.on('select2:open', () => {
        setTimeout(
          () => document.querySelector('.select2-search__field').focus(),
          0,
        )
      })

      const assignedToUserSelect = this.$form
        .find('#task_assignedToUser')
        .select2({
          dropdownCssClass: 'project-select-drop',
          dropdownAdapter: $.fn.select2.amd.require(
            'CustomDropdownSearchAdapter',
          ),
          placeholderForSearch: Translator.trans('search.or_filter_user'),
          width: '100%',
        })

      assignedToUserSelect.on('select2:open', () => {
        setTimeout(
          () => document.querySelector('.select2-search__field').focus(),
          0,
        )
      })
    }

    const datePickerInput = this.$document.find('#task_shouldBeFinished')

    if (typeof taskId !== 'undefined' && taskId !== '') {
      this.initLabels()
      this.AddTime = new AddTime()
      // mountRegularReact(AddTimeLogDialog)
      this.Comments = new Comments('.task-comments')
      this.initSchedule()
    }

    if (datePickerInput.length > 0) {
      datePickerInput.datepicker()
      localeDatepicker()

      if (typeof taskId !== 'undefined' && taskId !== '') {
        const date = datePickerInput.val()
        const dateArray = date.split('/')

        datePickerInput.datepicker(
          'setDate',
          $.datepicker.formatDate(
            $.datepicker._defaults.dateFormat,
            date === ''
              ? null
              : new Date(dateArray[2], dateArray[1] - 1, dateArray[0]),
          ),
        )
      }
    }

    const $taskProjectSelect = this.$document.find('#task_project')

    $taskProjectSelect.on('change', async (e) => {
      const value = e.currentTarget.value

      try {
        const response = await request(
          Routing.generate('app_projects_attributes', { projectId: value }),
          'GET',
        )
        this.$document
          .find('#task_invoiceStatus')
          .prop('checked', response.isBillable)
      } catch (error) {
        console.log(error) //eslint-disable-line
      }
    })

    if (this.$downloadAllAttachmentsBtn.length > 0) {
      this.$downloadAllAttachmentsBtn.on('click', (e) => {
        e.preventDefault()

        const $this = $(e.currentTarget)
        const jsZipInstance = new jsZip()
        const jsZipFolder = jsZipInstance.folder('attachments')

        const array = this.$target
          .find('a[data-file-name]')
          .toArray()
          .map(async (element) => {
            const $file = $(element)

            return fetch($file.attr('href'))
              .then(async (res) => res.blob())
              .then((blob) => {
                jsZipFolder.file($file.data('file-name'), blob)
              })
              .catch((err) => console.log(err)) //eslint-disable-line
          })

        Promise.all(array).then((data) => {
          jsZipFolder.generateAsync({ type: 'blob' }).then((content) => {
            const a = document.createElement('a')
            document.body.append(a)
            a.style = 'display: none'

            const url = window.URL.createObjectURL(content)
            a.href = url
            a.download = `${$this.data('task-alias').toUpperCase()}.zip`
            a.click()
            window.URL.revokeObjectURL(url)
            a.remove()
          })
        })
      })
    }
  }

  initLabels() {
    this.labels = new Labels()
  }

  initSchedule() {
    this.schedule = new Schedule()
  }

  static async stop(taskId, comment = null) {
    const { request } = useHttp()

    //@todo copied from old code... not sure if is used... remove if no needed
    $('.task-button').each(function () {
      $(this).prop('disable', true)
    })
    try {
      $(document).trigger('timer-stop')
      const response = await request(
        Routing.generate('app_ajax_task_stop', { id: taskId }),
        'POST',
        {
          comment,
        },
      )

      if (response.success) {
        window.location.reload()
      } else {
        $(document).trigger('timer-start')
        useNotifications('error', response.message)
      }
    } catch (error) {
      console.log(error) //eslint-disable-line
    }
  }

  static async close(taskId) {
    const { request } = useHttp()

    try {
      const response = await request(
        Routing.generate('app_task_close', { taskId }),
      )

      if (response.success) {
        window.location.reload()
      } else {
        useNotifications('error', response.message)
      }
    } catch (error) {
      console.log(error) //eslint-disable-line
    }
  }
}
