import Pristine from 'pristinejs'
import Layout from '@core/Layout'

import DropZone from '@components/DropZone'
import { Spinner } from '@components/Spinner'
import { useNotifications } from '@utils/useNotifications'
import { useHttp } from '@utils/useHttp'
import { stripLinkTag } from '@utils/stripHtmlTags'
import { Mentions } from '../../Mentions'
import { mountReact } from '../../../@utils/mountReact'
import React from 'react'
export default class Comment extends Layout {
  constructor(selector, callback) {
    super({
      selector: selector,
    })

    this.selector = selector
    this.callback = callback
    this.pristine = null
    this.commentId = this.selector.split('-')[1]
    this.taskId = null
    this.$form = this.$target.find('form')
    this.$removeCommentModal = this.$document.find('.remove-comment-modal')
    this.reactField = null
    this.reactRoot = null

    this.init()
  }

  init() {
    if (this.$form.get(0) !== undefined) {
      this.stripHtmlTagsForEditComment()
      this.mountReact()
      this.bindEvents()
      this.initializeValidation()
      this.initUpload()
    }
  }

  mountReact() {
    const input = this.$form.find('textarea.form-control')
    const ref = (this.reactField = React.createRef())
    this.reactRoot = mountReact(
      <Mentions
        ref={ref}
        value={input.val()}
        onChange={(event) => {
          input.val(event.target.value).trigger('change')
        }}
      />,
      input[0]
    )
  }

  stripHtmlTagsForEditComment() {
    const inputMessageValue = stripLinkTag(
      this.$target.find('.edit-block').find(`#comment${this.commentId}_message`).val()
    )

    this.$target.find('.edit-block').find(`#comment${this.commentId}_message`).attr('value', inputMessageValue)
    this.$target.find('.edit-block').find('.comment-message').html(inputMessageValue)
  }

  bindEvents() {
    this.$target.find('.edit-btn').on('click', (e) => {
      e.preventDefault()

      const $this = $(e.currentTarget)
      this.taskId = $this.data('task-id')
      this.$target.find(`.comment-edit-${this.commentId}`).toggleClass('hidden')
    })

    this.$target.find('.remove-btn').on('click', (e) => {
      e.preventDefault()
      const $this = $(e.currentTarget)

      this.$removeCommentModal.modal('show')

      this.$removeCommentModal
        .find('.remove-comment-btn')
        .off('click')
        .on('click', async (e) => {
          Spinner(e.target).prepend()
          this.toggleDisabled(e.target, true)

          try {
            await this.delete($this, $this.data('comment-id'))
            Spinner(e.target).remove()
            this.toggleDisabled(e.target, false)
            this.$removeCommentModal.modal('hide')
          } catch (e) {
            console.log(e) //eslint-disable-line
          }
        })
    })

    this.$form.find('button').on('click', (e) => {
      e.preventDefault()
      const $this = $(e.target)

      if (typeof taskId === 'undefined') {
        return
      }
      const parentForm = $this.parents('form')

      if (taskId && parentForm.length) {
        const formData = new FormData(parentForm[0])

        this.update(taskId, formData, $this)
      }
      return false
    })
  }

  initializeValidation() {
    this.pristine = new Pristine(this.$form.get(0))
  }

  async update(taskId, formData, buttonElement) {
    const { request } = useHttp()

    formData = this.prepareFormAttachments(formData)
    Spinner(buttonElement).prepend()
    this.toggleDisabled(buttonElement, true)

    try {
      const response = await request(
        Routing.generate('ajax_app_edit_task_comment', { taskId, commentId: this.commentId }),
        'POST',
        formData
      )

      if (response.success) {
        useNotifications('success', Translator.trans('push_notification.task.comment.success'))
        this.callback()
      } else {
        Spinner(buttonElement).remove()
        this.toggleDisabled(buttonElement, false)
        useNotifications('error', Translator.trans('push_notification.task.comment.error'))
      }
    } catch (e) {
      console.log(e) //eslint-disable-line
    }
  }

  async delete(buttonElement, commentId) {
    const { request } = useHttp()

    this.toggleDisabled(buttonElement, true)

    try {
      await request(Routing.generate('ajax_app_remove_task_comment', { taskId: taskId, commentId: commentId }), 'POST')

      this.$target.remove()
      useNotifications('success', Translator.trans('push_notification.task.comment.removed'))
    } catch (e) {
      console.log(e) //eslint-disable-line
    }
  }

  prepareFormAttachments(formData) {
    formData.append(`comment[commentId]`, this.commentId)

    this.DropZone.files.forEach((value, index) => {
      formData.append(`comment${this.commentId}[fileUploadAttachments][]`, value)
    })

    this.DropZone.images.forEach((value, index) => {
      formData.append(`comment${this.commentId}[fileUploadAttachments][]`, value)
    })

    return formData
  }

  initUpload() {
    this.DropZone = new DropZone(this.$form)
  }
}
