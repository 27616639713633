//@TODO Style files should be imported in related components and handled with webpack.
// import "./styles.scss";

import Task from './Task'

$(document).ready(() => {
  new Task()
})

export default Task
