import Layout from '@core/Layout'

import { localeDatepicker } from '@utils/localeDatepicker'
import { useHttp } from '@utils/useHttp'
import { useNotifications } from '@utils/useNotifications'

import {
  FREQUENCY_MAPPING,
  FREQUENCY_VALUE,
  MONTHS_MAPPING,
  WEEKS_DAYS_MAPPING,
} from './consts'

export default class Schedule extends Layout {
  constructor(options) {
    options = {
      apiEndPoints: {
        post: Routing.generate('app_schedule_create', { taskId }), //endpoint to post new schedule
        // getSchedule: Routing.generate('app_schedule_get'), //endpoint to Get single schedule
        getAllSchedules: Routing.generate('app_schedules_get_for_task', {
          taskId,
        }), //endpoint to Get All schedules for task
        updateSchedule: (scheduleId) =>
          Routing.generate('app_schedule_update', { scheduleId }), //endpoint to update schedule
        deleteSchedule: (scheduleId) =>
          Routing.generate('app_schedule_delete', { scheduleId }), //endpoint to Delete schedule
      },
      ...options,
    }
    super({
      selector: '.schedule-box',
    })

    this.data = []

    this.apiEndPoints = options.apiEndPoints
    this.$schedules = this.$target.find('.schedules')
    this.$form = this.$target.find('.schedule-box-form')
    this.$selectedRow = this.$form.find('.schedule-box-selected-row')
    this.$formPickers = this.$form.find('.schedule-box-form-pickers')
    this.$timePickerBlock = this.$formPickers.find('.time-picker-block')

    this.stHour = this.$timePickerBlock.find('.time-group-start .time-hour')
    this.stMinute = this.$timePickerBlock.find('.time-group-start .time-minute')
    this.endHour = this.$timePickerBlock.find('.time-group-end .time-hour')
    this.endMinute = this.$timePickerBlock.find('.time-group-end .time-minute')

    this.$dayPickerBlock = this.$formPickers.find('.day-picker-block')

    this.$frequencySelect = this.$timePickerBlock.find(
      '#schedule-box-frequency-select',
    )
    this.$durationSelect = this.$timePickerBlock.find(
      '#schedule-box-duration-select',
    )

    this.$everyDaySelect = this.$dayPickerBlock.find(
      '#schedule-box-every-day-select',
    )
    this.$everyWeekSelect = this.$dayPickerBlock.find(
      '#schedule-box-every-week-select',
    )

    this.$btns = this.$target.find('.schedule-box-form-buttons')
    this.$confirmBtn = this.$form.find('.confirm-schedule-btn')
    this.$updateBtn = this.$form.find('.update-schedule-btn')
    this.$cancelBtn = this.$form.find('.cancel-update-schedule-btn')

    this.todayDateFormatted = moment(new Date()).format('DD.MM.YYYY')

    if (this.$target.length > 0) {
      this.init()
    } else {
      this.fetchSchedules()
    }
  }

  async init() {
    await this.fetchSchedules()

    if (taskId && (typeof taskId !== 'undefined' || taskId !== '')) {
      this.toggleHidden(this.$btns, false)
      this.$btns.toggleClass('new-task-mode', false)
    }

    localeDatepicker()
    this.$target.find('.calendar').datepicker({
      onSelect: () => {
        this.updateSelectedRow()
      },
    })

    const defaultConfig = {
      width: '100%',
      minimumResultsForSearch: 6,
    }

    this.$frequencySelect.select2({
      ...defaultConfig,
    })

    this.$durationSelect.select2({
      ...defaultConfig,
    })
    this.$durationSelect
      .closest('.repeat-duration')
      .find('.date-range')
      .text(`${this.todayDateFormatted} - ${this.todayDateFormatted}`)

    this.$everyDaySelect.select2({
      ...defaultConfig,
      width: '30px',
    })

    this.$everyWeekSelect.select2({
      ...defaultConfig,
      width: '30px',
    })

    this.bindEvents()

    if (this.data.length > 0) {
      this.$target.find('#schedule-box-collapse-checkbox').prop('checked', true)
      this.editSchedule(this.data.at(-1).id)
    }
  }

  async fetchSchedules() {
    const { request } = useHttp()

    if (typeof taskId === 'undefined' || taskId === '') {
      return
    }

    try {
      const response = await request(this.apiEndPoints.getAllSchedules, 'GET')
      this.data = response

      this.$schedules.empty()

      if (this.data.length > 0) {
        const formatFrequency = (schedule) => {
          const { frequencyType, frequencyTypeNumbers } = schedule
          let frequency = FREQUENCY_VALUE[frequencyType]

          if (frequencyType === 'D' && frequencyTypeNumbers.length > 0) {
            frequency =
              frequency = `${Translator.trans('task.schedule.title-generated.every-day')} ${
                frequencyTypeNumbers[0] === 1 ? '' : frequencyTypeNumbers[0]
              } ${Translator.trans('task.schedule.title-generated.day')}`
          }

          if (frequencyType === 'W' && frequencyTypeNumbers.length > 0) {
            frequency =
              frequency = `${Translator.trans('task.schedule.title-generated.every-week')} ${
                frequencyTypeNumbers[0] === 1 ? '' : frequencyTypeNumbers[0]
              } ${Translator.trans('task.schedule.title-generated.week')}`
          }

          if (frequencyType === 'M' && frequencyTypeNumbers.length > 0) {
            frequency = schedule.frequencyTypeNumbers
              .map((m) => MONTHS_MAPPING[m])
              .join(', ')
          }

          return frequency
        }

        const formatDaysArray = (schedule) => {
          let days = []

          if (schedule.days.length === 0) {
            days.push(moment(schedule.startDate.date).format('YYYY-MM-DD'))
          } else if (schedule.frequencyType === 'M') {
            days = schedule.days
          } else {
            for (const d of schedule.days) days.push(WEEKS_DAYS_MAPPING[d])
          }

          return days
        }

        if (this.$target.length > 0) {
          for (const [index, schedule] of this.data.entries()) {
            this.$schedules.append(`
              <div class="schedule-${schedule.id}" data-schedule-id="${schedule.id}">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 6L11 11M11 6L6 11M16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5Z" stroke="#F74A42" stroke-linecap="round"/>
                </svg> ${this.generateScheduleTitle(
                  formatFrequency(schedule),
                  schedule.startTime,
                  schedule.endTime,
                  formatDaysArray(schedule),
                )}
              </div>
            `)
          }
        } else {
          const $schedulesForTaskPlaceholder = this.$document.find(
            '.task-details-smart-description .schedules-for-task',
          )
          const scheduleFirstTitle = this.generateScheduleTitle(
            formatFrequency(this.data[0]),
            this.data[0].startTime,
            this.data[0].endTime,
            formatDaysArray(this.data[0]),
          )
          $schedulesForTaskPlaceholder
            .toggleClass('hidden', false)
            .append(`<b>${scheduleFirstTitle}</b>`)

          if (this.data.length > 1) {
            $schedulesForTaskPlaceholder.append(`
              <span style="display: flex; align-items: center;">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="7.5" cy="7.5" r="7" stroke="#919191"/>
                  <path d="M10 10L7.5 7.5V3" stroke="#919191" stroke-linecap="round"/>
                </svg>
                (+${this.data.length - 1} ${Translator.trans('task.schedule.more')})
              </span>
            `)

            const [, ...rest] = this.data
            $schedulesForTaskPlaceholder.append(
              `<div class="tooltip-more-schedules">${rest
                .map((r) =>
                  this.generateScheduleTitle(
                    FREQUENCY_VALUE[r.frequencyType],
                    r.startTime,
                    r.endTime,
                    formatDaysArray(r),
                  ),
                )
                .join(', ')}</div>`,
            )
          }
        }
      }

      // Re-bind each schedule after fetch
      if (this.$target.length > 0) {
        this.$schedules
          .find('div[class^="schedule-"]')
          .on('click', async (e) => {
            const $target = $(e.target)
            const scheduleId = $(e.currentTarget).data('schedule-id')

            if ($target.is('svg')) {
              await this.deleteSchedule(scheduleId)
            } else {
              this.editSchedule(scheduleId)
            }
          })
      }
    } catch (error) {
      useNotifications('error', error.message)
      console.log(error) //eslint-disable-line
    }
  }

  async postSchedule(query) {
    try {
      const { request } = useHttp()

      await request(this.apiEndPoints.post, 'POST', query)
      useNotifications(
        'success',
        Translator.trans('push_notification.task.schedule.success'),
      )
      this.resetEdit()
      await this.fetchSchedules()
    } catch (error) {
      useNotifications('error', error.message)
      console.log(error) //eslint-disable-line
    }
  }

  async deleteSchedule(scheduleId) {
    const { request } = useHttp()

    try {
      await request(this.apiEndPoints.deleteSchedule(scheduleId), 'POST')
      this.resetEdit()
      await this.fetchSchedules()
    } catch (error) {
      useNotifications('error', error.message)
      console.log(error) //eslint-disable-line
    }
  }

  async updateSchedule(scheduleId, query) {
    const { request } = useHttp()

    try {
      await request(this.apiEndPoints.updateSchedule(scheduleId), 'PUT', query)
      useNotifications(
        'success',
        Translator.trans('push_notification.task.schedule.updated'),
      )
      await this.fetchSchedules()
    } catch (error) {
      useNotifications('error', error.message)
      console.log(error) //eslint-disable-line
    }
  }

  bindEvents() {
    this.$timePickerBlock
      .find('input.time-input')
      .on('keydown keyup', (e) => {
        const $this = $(e.target)
        const value = Number.parseInt($this.val()) || 0
        const numberMax = $this.attr('max')
        const numberMin = $this.attr('min')

        if (value <= numberMax && value >= numberMin) {
          $this.data('old', value)
        } else {
          $this.val($this.data('old'))
        }

        this.updateSelectedRow()
      })
      .on('change', (e) => {
        const $this = $(e.target)
        const value = $this.val()

        if (value.length < 2) {
          $this.val(`0${value}`)
        }

        if (value.length === 0) {
          $this.val('00')
        }

        this.updateSelectedRow()
      })

    this.$frequencySelect.on('change', (e) => {
      this.$dayPickerBlock.find('> .active').toggleClass('active', false)
      this.$dayPickerBlock
        .find(`.${FREQUENCY_MAPPING[e.target.value]}`)
        .toggleClass('active', true)
      const areSelected = this.$dayPickerBlock.find(
        '.monthly .months .month.active',
      ).length
      this.$durationSelect
        .closest('.repeat-duration')
        .toggleClass('hidden', Boolean(areSelected) && e.target.value === 'M')
      this.$durationSelect.trigger('change')
      this.updateSelectedRow()
    })

    this.$durationSelect.on('change', (e) => {
      let durationValue = Number.parseInt(e.target.value)

      const today = new Date()
      const frequencyType = this.$frequencySelect.val()

      if (frequencyType !== 'O' && durationValue === 0) {
        durationValue = 12
      }

      this.$durationSelect
        .closest('.repeat-duration')
        .find('.date-range')
        .text(
          `${this.todayDateFormatted} - ${moment(
            new Date(today.setMonth(today.getMonth() + durationValue)),
          ).format('DD.MM.YYYY')}`,
        )
    })

    this.$dayPickerBlock.find('.weekly .days .day').on('click', (e) => {
      const currentTarget = e.currentTarget

      if (currentTarget.classList.contains('select-all-days-btn')) {
        this.$dayPickerBlock
          .find('.weekly .days .day:not(.select-all-days-btn)')
          .toggleClass('active', !currentTarget.classList.contains('selected'))
        currentTarget.classList.contains('selected')
          ? currentTarget.classList.remove('selected')
          : currentTarget.classList.add('selected')
      } else {
        currentTarget.classList.contains('active')
          ? currentTarget.classList.remove('active')
          : currentTarget.classList.add('active')
      }

      this.updateSelectedRow()
    })

    this.$dayPickerBlock.find('.monthly .days .day').on('click', (e) => {
      const currentTarget = e.currentTarget

      currentTarget.classList.contains('active')
        ? currentTarget.classList.remove('active')
        : currentTarget.classList.add('active')

      this.updateSelectedRow()
    })

    this.$dayPickerBlock.find('.monthly .months .month').on('click', (e) => {
      const currentTarget = e.currentTarget

      currentTarget.classList.contains('active')
        ? currentTarget.classList.remove('active')
        : currentTarget.classList.add('active')

      const areSelected = this.$dayPickerBlock.find(
        '.monthly .months .month.active',
      ).length

      this.$durationSelect.val('0').trigger('change')
      this.$durationSelect
        .closest('.repeat-duration')
        .toggleClass('hidden', Boolean(areSelected))
      this.updateSelectedRow()
    })

    this.$everyDaySelect.on('change', (e) => {
      this.updateSelectedRow()
    })

    this.$everyWeekSelect.on('change', (e) => {
      this.updateSelectedRow()
    })

    this.$dayPickerBlock.find('a[data-toggle="tab"]').on('shown.bs.tab', () => {
      this.updateSelectedRow()
    })

    this.$confirmBtn.on('click', async (e) => {
      e.preventDefault()
      const { query } = this.prepareQuery()

      await this.postSchedule(query)
    })

    this.$updateBtn.on('click', async (e) => {
      e.preventDefault()
      const { query } = this.prepareQuery()

      await this.updateSchedule($(e.currentTarget).data('schedule-id'), query)
    })

    this.$cancelBtn.on('click', () => {
      this.resetEdit()
    })
  }

  editSchedule(scheduleId) {
    const {
      days,
      endDate,
      endTime,
      frequencyType,
      frequencyTypeNumbers,
      startDate,
      startTime,
      title,
    } = this.data.find((s) => s.id === scheduleId)

    this.toggleEditButtons(true, scheduleId)

    this.$selectedRow.find('input').val(title)
    this.setTimeSelected(startTime, endTime)
    this.$frequencySelect.val(frequencyType).trigger('change')

    const _startDate = new Date(startDate.date)
    const _endDate = new Date(endDate.date)
    const durationMonths = _endDate.getMonth() - _startDate.getMonth() || 0

    this.$durationSelect
      .val(durationMonths > 6 ? 0 : durationMonths)
      .trigger('change')

    const byFrequencyType = {
      O: () => {
        const formattedStartedDate = $.datepicker.formatDate(
          $.datepicker._defaults.dateFormat,
          new Date(moment(startDate.date).format('YYYY-MM-DD')),
        )

        this.$target
          .find('.calendar')
          .datepicker('setDate', formattedStartedDate)
      },
      D: () => {
        this.$everyDaySelect.val(frequencyTypeNumbers).trigger('change')
      },
      W: () => {
        this.$everyWeekSelect.val(frequencyTypeNumbers).trigger('change')

        if (days.length > 0) {
          for (const d of days)
            this.$dayPickerBlock
              .find(`.weekly .days .day[data-day-number="${d}"]`)
              ?.toggleClass('active', true)
        }
      },
      M: () => {
        if (days.length > 0) {
          for (const d of days)
            this.$dayPickerBlock
              .find(`.monthly .days .day[data-day-number="${d}"]`)
              ?.toggleClass('active', true)
        }

        if (frequencyTypeNumbers.length > 0) {
          this.$durationSelect
            .closest('.repeat-duration')
            .toggleClass('hidden', true)
          for (const m of frequencyTypeNumbers)
            this.$dayPickerBlock
              .find(`.monthly .months .month[data-month-number="${m}"]`)
              ?.toggleClass('active', true)
        }
      },
    }

    byFrequencyType[frequencyType]()

    this.updateSelectedRow()
  }

  resetEdit() {
    this.toggleEditButtons(false)

    this.stHour.val('')
    this.stMinute.val('')
    this.endHour.val('')
    this.endMinute.val('')
    this.$frequencySelect.val('O').trigger('change')
    this.$durationSelect.val('0').trigger('change')

    this.$dayPickerBlock
      .find('.weekly .days .day')
      ?.toggleClass('active', false)
    this.$dayPickerBlock
      .find('.monthly .days .day')
      ?.toggleClass('active', false)
    this.$dayPickerBlock
      .find('.monthly .months .month')
      ?.toggleClass('active', false)

    const formattedStartedDate = $.datepicker.formatDate(
      $.datepicker._defaults.dateFormat,
      new Date(),
    )

    this.$target.find('.calendar').datepicker('setDate', formattedStartedDate)

    this.updateSelectedRow()
  }

  toggleEditButtons(bool, scheduleId = null) {
    this.toggleHidden(this.$confirmBtn, bool)
    this.toggleHidden(this.$updateBtn, !bool)
    this.toggleHidden(this.$cancelBtn, !bool)

    this.$updateBtn.data('schedule-id', scheduleId)
  }

  getTimeSelected() {
    const stHour = this.stHour.val()
    const stMinute = this.stMinute.val()
    const endHour = this.endHour.val()
    const endMinute = this.endMinute.val()

    return {
      startTime: `${stHour || '00'}:${stMinute || '00'}`,
      endTime: `${endHour || '00'}:${endMinute || '00'}`,
    }
  }

  setTimeSelected(startTime, endTime) {
    const stHour = startTime.split(':')[0]
    const stMinute = startTime.split(':')[1]
    const endHour = endTime.split(':')[0]
    const endMinute = endTime.split(':')[1]

    this.stHour.val(stHour)
    this.stMinute.val(stMinute)
    this.endHour.val(endHour)
    this.endMinute.val(endMinute)
  }

  generateScheduleTitle(frequency, startTime, endTime, days) {
    const time = `${startTime} - ${endTime}`

    return `${time ? `${time}, ` : ''}${frequency}${
      days.length > 0
        ? ` - ${Translator.trans('task.schedule.title-generated.on')} ${days.join(', ')}`
        : ''
    }`
  }

  updateSelectedRow() {
    const frequencyType = this.$frequencySelect.val()
    let frequency = FREQUENCY_VALUE[frequencyType]
    const { endTime, startTime } = this.getTimeSelected()

    let days = []

    const byFrequency = {
      O: () => {
        days = [
          moment(this.$target.find('.calendar').datepicker('getDate')).format(
            'Y-MM-DD',
          ),
        ].flat()
      },
      D: () => {
        const every = this.$everyDaySelect.val()

        frequency = `${Translator.trans('task.schedule.title-generated.every-day')} ${
          every === '1' ? '' : every
        } ${Translator.trans('task.schedule.title-generated.day')}`
      },
      W: () => {
        const every = this.$everyWeekSelect.val()

        frequency = `${Translator.trans('task.schedule.title-generated.every-week')} ${
          every === '1' ? '' : every
        } ${Translator.trans('task.schedule.title-generated.week')}`
        days = this.$dayPickerBlock
          .find('.weekly .days .day.active')
          .toArray()
          .map((day) => WEEKS_DAYS_MAPPING[$(day).data('day-number')])
      },
      M: () => {
        frequency = `${Translator.trans('task.schedule.title-generated.every-month')} ${Translator.trans(
          'task.schedule.title-generated.month',
        )}`

        if (
          this.$dayPickerBlock.find('.monthly .months .month.active').length > 0
        ) {
          frequency = this.$dayPickerBlock
            .find('.monthly .months .month.active')
            .toArray()
            .map((m) => MONTHS_MAPPING[$(m).data('month-number')])
            .join(', ')
        }

        days = this.$dayPickerBlock
          .find('.monthly .days .day.active')
          .toArray()
          .map((day) => $(day).data('day-number'))
      },
    }

    byFrequency[frequencyType]()

    this.$selectedRow
      .find('input')
      .val(this.generateScheduleTitle(frequency, startTime, endTime, days))
    const valid = this.validate()

    this.$confirmBtn.prop('disabled', !valid)
    this.$updateBtn.prop('disabled', !valid)
  }

  prepareQuery() {
    const frequencyType = this.$frequencySelect.val()
    const { endTime, startTime } = this.getTimeSelected()

    const byFrequency = {
      O: () => ({
        startDate: moment(
          this.$target.find('.calendar').datepicker('getDate'),
        ).format('Y-MM-DD'),
      }),
      D: () => ({
        frequencyTypeNumbers: [Number.parseInt(this.$everyDaySelect.val())], // Repeat every n day(s)
        days: [],
      }),
      W: () => ({
        frequencyTypeNumbers: [Number.parseInt(this.$everyWeekSelect.val())], // Repeat every n week(s)
        days: this.$dayPickerBlock
          .find('.weekly .days .day.active')
          .toArray()
          .map((day) => $(day).data('day-number')), // Selected days
      }),
      M: () => ({
        frequencyTypeNumbers: this.$dayPickerBlock
          .find('.monthly .months .month.active')
          .toArray()
          .map((m) => Number.parseInt($(m).data('month-number'))), // Repeat every n months(s)
        days: this.$dayPickerBlock
          .find('.monthly .days .day.active')
          .toArray()
          .map((day) => $(day).data('day-number')), // Selected days
      }),
    }

    const query = {
      title: this.$selectedRow.find('input').val(), // All selections phrase
      startTime,
      endTime,
      startDate: moment(new Date()).format('Y-MM-DD'),
      frequencyType,
      ...byFrequency[frequencyType](),
    }

    const startDate = new Date(query.startDate)
    const durationValue = Number.parseInt(this.$durationSelect.val())

    query.endDate = query.startDate

    const monthsSelected = this.$dayPickerBlock.find(
      '.monthly .months .month.active',
    ).length

    if (durationValue > 0 && !monthsSelected) {
      query.endDate = moment(
        new Date(startDate.setMonth(startDate.getMonth() + durationValue)),
      ).format('Y-MM-DD')
    }

    if (frequencyType !== 'O') {
      if (frequencyType === 'M' && monthsSelected) {
        query.endDate = new Date(startDate.getFullYear(), 12, 0, 23, 59, 59)
      } else if (!durationValue) {
        query.endDate = new Date(startDate.setMonth(startDate.getMonth() + 12)) // if repeat duration None - startDate + 12 months
      }
    }

    return {
      query,
    }
  }

  validate() {
    let valid = false
    const frequencyType = this.$frequencySelect.val()

    if (
      this.stHour.val() !== '' &&
      this.stMinute.val() !== '' &&
      this.endHour.val() !== '' &&
      this.endMinute.val() !== ''
    ) {
      const byFrequency = {
        O: () => {
          valid = true
        },
        D: () => {
          valid = true
        },
        W: () => {
          if (
            this.$dayPickerBlock.find('.weekly .days .day.active').length > 0
          ) {
            valid = true
          }
        },
        M: () => {
          if (
            this.$dayPickerBlock.find('.monthly .days .day.active').length >
              0 ||
            this.$dayPickerBlock.find('.monthly .months .month.active').length >
              0
          ) {
            valid = true
          }
        },
      }

      byFrequency[frequencyType]()
    }

    return valid
  }
}
