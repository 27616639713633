import Pristine from 'pristinejs'
import Layout from '@core/Layout'

import DropZone from '@components/DropZone'
import { Spinner } from '@components/Spinner'
import { useNotifications } from '@utils/useNotifications'
import { useHttp } from '@utils/useHttp'
import { Tasks } from '../../entities/tasks'
import Comment from './Comment'
import { mountReact } from '../../@utils/mountReact'
import { Mentions } from '../Mentions'
import React, { useRef } from 'react'

let nextPage = 1
let currentPage = 1

export default class Comments extends Layout {
  constructor(selector) {
    super({
      selector: selector,
    })

    this.$form = this.$document.find('.task-comment-form')
    this.commentsHtml = ''
    this.page = 1
    this.pristine = null
    this.reactField = null
    this.reactRoot = null

    this.init()
  }

  async init() {
    this.toggleLoader(this.$target, true)

    try {
      await this.fetch(taskId)
      this.toggleLoader(this.$target, false)
    } catch (e) {
      console.log(e) //eslint-disable-line
    }

    if (this.$form.length) {
      this.mountReact()
      this.bindEvents()
      this.initLightbox()
      this.initializeValidation()
      this.initUpload()
    }
  }

  mountReact() {
    const input = this.$form.find('textarea.form-control')
    const ref = (this.reactField = React.createRef())
    this.reactRoot = mountReact(
      <Mentions
        ref={ref}
        value={input.val()}
        onChange={(event) => {
          input.val(event.target.value).trigger('change')
        }}
      />,
      input[0]
    )
  }

  bindEvents() {
    //Before the user try to change the browser tab we try to save his draft comment message
    window.onbeforeunload = () => {
      if (typeof taskId === 'undefined') {
        return null
      }

      let message = this.$form.find('textarea.form-control').val()
      if (!message) {
        return null
      }

      message = message.trim()
      if (message) {
        this.draft(taskId, this.$form.serialize())
        return 'Are you sure ?'
      }
    }

    if (window.location.hash === '#comments') {
      const elementToScroll = this.$document.find('.task-comments')

      if (!elementToScroll) return

      window.scrollTo({
        top: elementToScroll[0].offsetTop,
        behavior: 'smooth',
      })
    }

    //Scroll animation for "Add new comment" button, which us under the task view
    this.$document.on('click', '.add-comment', (e) => {
      e.preventDefault()
      this.scrollToForm()
    })

    //Pagination button event
    this.$document.on('click', '.task-comments-pagination .load-more', (e) => {
      e.preventDefault()

      if (typeof taskId === 'undefined') {
        return
      }

      this.setPages()
      this.loadNewPage(taskId)
    })


    this.$form.find('button').on('click', (e) => {
      e.preventDefault()
      const $this = $(e.target)

      if (typeof taskId === 'undefined') {
        return
      }

      const parentForm = $this.parents('form')

      if (taskId && parentForm.length) {
        const valid = this.pristine.validate()

        if (valid) {
          const formData = new FormData(parentForm[0])
          this.post(taskId, formData, $this)
        }
      }

      return false
    })
  }

  initializeValidation() {
    this.pristine = new Pristine(this.$form.get(0))
  }

  initEachComment() {
    this.$target.find('.post').each((i, post) => {
      new Comment(`#${post.id}`, async () => {
        this.toggleLoader(this.$target, true)

        try {
          await this.fetch(taskId)
          this.initLightbox()
          this.toggleLoader(this.$target, false)
        } catch (e) {
          console.log(e) //eslint-disable-line
        }
      })
    })
  }

  reInitForm() {
    this.$form = this.$document.find('.task-comment-form')
    this.initializeValidation()
    this.bindEvents()
  }

  async fetch(taskId, pageNumber = null) {
    const { request } = useHttp()

    const query = {
      taskId: taskId,
    }

    if (pageNumber) {
      query.page = pageNumber
    }

    try {
      const { html } = await request(Routing.generate('app_task_comments_load', query), 'GET')
      this.commentsHtml = html
      this.$target.empty().html(this.commentsHtml)
      this.initEachComment()
    } catch (e) {
      console.log(e) //eslint-disable-line
    }
  }

  async post(taskId, formData, buttonElement) {
    const { request } = useHttp()

    formData = this.prepareFormAttachments(formData)
    Spinner(buttonElement).prepend()
    this.toggleDisabled(buttonElement, true)

    try {
      const response = await request(Routing.generate('app_task_comment_add', { taskId }), 'POST', formData)

      if (response.status === 413) {
        Spinner(buttonElement).remove()
        this.toggleDisabled(buttonElement, false)
        useNotifications('error', Translator.trans('push_notification.task.comment.error413'))
      }

      if (response.success) {
        useNotifications('success', Translator.trans('push_notification.task.comment.success'))
        this.fetch(taskId).then(() => this.reloadForm(response.form))
        this.DropZone.reset()
      } else {
        Spinner(buttonElement).remove()
        this.toggleDisabled(buttonElement, false)
        useNotifications('error', Translator.trans('push_notification.task.comment.error'))
      }
    } catch (e) {
      console.log(e) //eslint-disable-line
    }
  }

  prepareFormAttachments(formData) {
    this.DropZone.files.forEach((value, index) => {
      formData.append('comment[fileUploadAttachments][]', value)
    })

    this.DropZone.images.forEach((value, index) => {
      formData.append('comment[fileUploadAttachments][]', value)
    })

    return formData
  }

  initLightbox() {
    if ($('.for-comment a.uploaded-image').length > 0) {
      $('.for-comment a.uploaded-image').simpleLightbox()
    }
  }

  loadNewPage(taskId) {
    const self = this

    if (nextPage === currentPage) {
      $('.task-comments-pagination .load-more').hide()
      return
    }

    if (typeof nextPage !== 'undefined' && typeof currentPage !== 'undefined') {
      $.ajax({
        url: Routing.generate('app_task_comments_load', { taskId: taskId }),
        type: 'GET',
        data: { page: nextPage },
        dataType: 'JSON',
        beforeSend: function () {
          self.disableButton($('.task-comments-pagination .load-more'))
        },
        success: function (response) {
          if (response.success) {
            self.enableButton($('.task-comments-pagination .load-more'))

            const commentsBlock = $('.task-comments')
            if (commentsBlock.length && response.html) {
              $('.task-comments-pagination').remove()
              commentsBlock.append(response.html)

              //Now we need to remove pagination
              if (!$('ul.pagination li.active').next().length) {
                currentPage = nextPage
                $('.task-comments-pagination .load-more').hide()
              }
            }
          }
        },
      })
    }
  }

  reloadForm(newForm) {
    if (this.$form.length) {
      this.reactRoot?.unmount()
      this.$form.replaceWith(newForm)
      this.reInitForm()
      this.resetForm()
      this.mountReact()
      this.initUpload()
    }

    Tasks.loadInvitedUsers(taskId)
  }

  setPages() {
    const currentPageLi = $('ul.pagination').find('li.active')

    if (currentPageLi.length && currentPageLi.find('a').length) {
      currentPage = parseInt(currentPageLi.find('a').text())
    }

    const nextPageLi = currentPageLi.next()
    if (nextPageLi.find('a').length) {
      const nextPageMatch = nextPageLi
        .find('a')
        .attr('href')
        .match(/page=(\d+)/)
      if (nextPageMatch && typeof nextPageMatch[1] !== 'undefined' && parseInt(nextPageMatch[1])) {
        nextPage = parseInt(nextPageMatch[1])
      }
    }
  }

  initUpload() {
    this.DropZone = new DropZone(this.$form, '.task-details-wrapper')
  }

  resetForm() {
    this.$form.find('.comment-message').html('')
    this.$form.find('textarea.form-control').val('')
  }

  scrollToForm() {
    $('html, body').animate(
      {
        scrollTop: this.$form.offset().top,
      },
      700
    )
  }
}
