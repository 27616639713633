//https://stackoverflow.com/questions/5002111/how-to-strip-html-tags-from-string-in-javascript/5002161#5002161
export const stripHtmlTags = (strInputCode) => strInputCode.replace(/<\/?[^>]+(>|$)/g, '')

export const stripLinkTag = (strInputCode) => {
  return strInputCode.replace(/<a[^>]*>|<\/a>/g, '')
}

export const stripDivTag = (strInputCode) => {
  return strInputCode.replace(/<div[^>]*>|<\/div>/g, '')
}
