import ky from 'ky'
import debounce from 'lodash/debounce'
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { MentionsInput, Mention } from 'react-mentions'

import classNames from './Mentions.module.css'
import { Spinner } from './Spinner'

import type {
  DataFunc,
  MentionProps,
  MentionsInputProps,
  OnChangeHandlerFunc,
} from 'react-mentions'

const renderSuggestion: MentionProps['renderSuggestion'] = (
  suggestion,
  search,
  highlightedDisplay,
  index,
  focused,
) => {
  // @ts-expect-error
  const display = suggestion.display
  return (
    <div
      className={`
      user

      ${focused ? 'focused' : ''}
    `}
    >
      <b>{display}</b>
    </div>
  )
}

type Props = Pick<MentionsInputProps, 'onChange'> & {
  readonly value: string
}

export const Mentions = forwardRef(({ onChange, value }: Props, ref) => {
  const [input, setInput] = useState(value)
  const [isLoading, setLoading] = useState(false)
  const [abort, setAbort] = useState(new AbortController())
  const fetchUsers: DataFunc = async function (nickname, callback) {
    if (nickname.length === 0) {
      return
    }

    abort.abort()
    const newAbort = new AbortController()
    setAbort(newAbort)
    setLoading(false)
    // callback([{ id: '', display: '' }])

    try {
      const result = await ky(
        Routing.generate('app_user_find_nickname', { nickname }),
        {
          signal: newAbort.signal,
        },
      ).json<{
        raw: Array<{ id: string; display: string; fullName: string }>
      }>()

      callback(result.raw || [])
      setLoading(false)
    } catch {}
  }

  const debouncedFetch = useCallback(debounce(fetchUsers, 300), [abort])

  useImperativeHandle(ref, () => ({
    reset() {},
  }))

  const handleOnChange = useCallback<OnChangeHandlerFunc>((...arguments_) => {
    const [event] = arguments_
    setInput(event.target.value)
    if (onChange) {
      onChange(...arguments_)
    }
  }, [])
  return (
    <MentionsInput
      className="mentions"
      classNames={classNames}
      customSuggestionsContainer={(children) =>
        isLoading ? (
          <ul className={classNames.mentions__suggestions__list}>
            <li
              className={`
                ${classNames.mentions__suggestions__item}

                tw-flex tw-items-center tw-justify-center tw-space-x-1
              `}
            >
              <Spinner inner={children} /> <span>loading</span>
            </li>
          </ul>
        ) : (
          children
        )
      }
      onChange={handleOnChange}
      value={input}
    >
      <Mention
        appendSpaceOnAdd
        className={classNames.mentions__mention}
        data={debouncedFetch}
        displayTransform={(id, display) => `@${display}`}
        markup="@[__display__](user:__id__)"
        renderSuggestion={renderSuggestion}
        trigger="@"
      />
    </MentionsInput>
  )
})
