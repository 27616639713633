import Layout from '@core/Layout'

import { Spinner } from '@components/Spinner'
import { useHttp } from '@utils/useHttp'
import { useNotifications } from '@utils/useNotifications'

export default class AddTime extends Layout {
  constructor(options) {
    options = {
      apiEndPoints: {
        post: Routing.generate('app_ajax_task_time_log_create'), //endpoint to create new time log
      },
      ...options,
    }
    super({
      selector: '.task-add-time-wrapper',
    })

    this.apiEndPoints = options.apiEndPoints

    this.$simpleSelectTime = this.$target.find('#simple-select-time')
    this.$customTimeOption = this.$target.find('#custom-time')
    this.$exactTimeBox = this.$target.find('.task-time-exact')
    this.$customTimeBox = this.$target.find('.task-time-custom')
    this.$datePicker = this.$target.find('.date-input')
    this.$timeGroupTotal = this.$target.find('.time-group-total')
    this.$commentInput = this.$target.find('#add-time-comment')
    this.$submitButton = this.$target.find(
      '.task-time-bottom button[type=submit]',
    )
    this.init()
  }

  async init() {
    this.$simpleSelectTime.select2({
      minimumResultsForSearch: 5,
    })

    const datepickerConfiguration = {
      todayHighlight: true,
    }

    this.$datePicker.datepicker(datepickerConfiguration)

    this.bindEvents()
  }

  bindEvents() {
    this.$submitButton.on('click', async (e) => {
      e.preventDefault()
      const $this = $(e.target)
      let time = null
      let date = new Date()

      if (this.$target.find('#selected-time').is(':checked')) {
        time = this.$simpleSelectTime.val()
      } else {
        const selectedDate = this.$datePicker.datepicker('getDate')
        if (selectedDate !== null) {
          date = selectedDate
        }
      }

      Spinner($this).prepend()
      this.toggleDisabled($this, true)

      try {
        await this.postTime({
          id: taskId,
          time,
          date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
          startTime: this.$timeGroupTotal.data('startTime'),
          stopTime: this.$timeGroupTotal.data('stopTime'),
          comment: this.$commentInput.val(),
        })

        Spinner($this).remove()
        this.toggleDisabled($this, false)
      } catch (error) {
        console.log(error) //eslint-disable-line
      }
    })

    this.$document.find('.task-details-button.add-time').on('click', (e) => {
      const $this = $(e.currentTarget)

      $this.toggleClass('active')
      this.$target.slideToggle()
    })

    this.$target
      .find('input.time-input')
      .on('keydown keyup', (e) => {
        const $this = $(e.target)
        const value = Number.parseInt($this.val()) || 0
        const numberMax = $this.attr('max')
        const numberMin = $this.attr('min')

        if (value <= numberMax && value >= numberMin) {
          $this.data('old', value)
        } else {
          $this.val($this.data('old'))
        }
      })
      .on('change', (e) => {
        const $this = $(e.target)
        const value = $this.val()

        if (value.length < 2) {
          $this.val(`0${value}`)
        }

        if (value.length === 0) {
          $this.val('00')
        }
      })

    this.$target.find('.time-group input').on('change', () => {
      const stHour = this.$customTimeBox
        .find('.time-group-start .time-hour')
        .val()
      const stMinute = this.$customTimeBox
        .find('.time-group-start .time-minute')
        .val()
      const endHour = this.$customTimeBox
        .find('.time-group-end .time-hour')
        .val()
      const endMinute = this.$customTimeBox
        .find('.time-group-end .time-minute')
        .val()
      const startTime = stHour * 3600 + stMinute * 60
      const endTime = endHour * 3600 + endMinute * 60
      const totalTime = endTime - startTime

      if (totalTime > 0) {
        const totalHour = Math.trunc(totalTime / 3600)
        const newTotalTime = totalTime - totalHour * 3600
        let totalMinute = Math.trunc(newTotalTime / 60)

        if (totalMinute < 10) {
          totalMinute = `0${totalMinute}`
        }

        this.$timeGroupTotal.data(
          'startTime',
          `${stHour ? stHour : 0}:${stMinute ? stMinute : 0}:0`,
        )
        this.$timeGroupTotal.data(
          'stopTime',
          `${endHour ? endHour : 0}:${endMinute ? endMinute : 0}:0`,
        )
        this.$timeGroupTotal.find('.time-hour').val(totalHour)
        this.$timeGroupTotal.find('.time-minute').val(totalMinute)
      }
    })

    const showTaskTimeCustom = () => {
      if (this.$customTimeOption.is(':checked')) {
        this.$exactTimeBox.slideUp()
        this.$customTimeBox.slideDown()
      } else {
        this.$exactTimeBox.slideDown()
        this.$customTimeBox.slideUp()
      }
    }

    if (this.$customTimeOption.length > 0) {
      showTaskTimeCustom()
    }

    this.$customTimeOption.on('change', () => {
      showTaskTimeCustom()
    })

    this.$target.find('#selected-time').on('change', () => {
      showTaskTimeCustom()
    })
  }

  async postTime(query) {
    const { request } = useHttp()

    try {
      const response = await request(this.apiEndPoints.post, 'POST', query)

      if (response.success && response.total !== undefined) {
        this.$document.find('.js-total-time-spent b').text(response.total)
        this.$document.find('.task-details-button.add-time').click()
        useNotifications(
          'success',
          Translator.trans('push_notification.task.create.time.success'),
        )
      } else if (response.error) {
        useNotifications('error', response.error)
      }
    } catch (error) {
      useNotifications('error', error.message)
      console.log(error) //eslint-disable-line
    }
  }
}
