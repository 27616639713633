export const FREQUENCY_VALUE = {
  O: Translator.trans('task.schedule.frequency.once'),
  D: Translator.trans('task.schedule.frequency.daily'),
  W: Translator.trans('task.schedule.frequency.weekly'),
  M: Translator.trans('task.schedule.frequency.monthly'),
}

export const FREQUENCY_MAPPING = {
  O: 'once',
  D: 'daily',
  W: 'weekly',
  M: 'monthly',
  A: 'annually',
}

export const WEEKS_DAYS_MAPPING = {
  1: Translator.trans('task.schedule.title-generated.monday'),
  2: Translator.trans('task.schedule.title-generated.tuesday'),
  3: Translator.trans('task.schedule.title-generated.wednesday'),
  4: Translator.trans('task.schedule.title-generated.thursday'),
  5: Translator.trans('task.schedule.title-generated.friday'),
  6: Translator.trans('task.schedule.title-generated.saturday'),
  7: Translator.trans('task.schedule.title-generated.sunday'),
}

export const MONTHS_MAPPING = {
  1: Translator.trans('months.month-jan'),
  2: Translator.trans('months.month-feb'),
  3: Translator.trans('months.month-mar'),
  4: Translator.trans('months.month-apr'),
  5: Translator.trans('months.month-may'),
  6: Translator.trans('months.month-jun'),
  7: Translator.trans('months.month-jul'),
  8: Translator.trans('months.month-aug'),
  9: Translator.trans('months.month-sep'),
  10: Translator.trans('months.month-oct'),
  11: Translator.trans('months.month-nov'),
  12: Translator.trans('months.month-dec'),
}
